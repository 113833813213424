

.toggle-btn:active{
    color: white !important;
    background-color:#51585e !important;
    border-color: #51585e !important;
}
.toggle-btn:focus{
    color: white !important;
    background-color:#51585e !important;
    border-color: #51585e !important;
}


.forms{
    width: 75%;
    display: inline-block;
    padding: 3px;
}
.form{
    background-color: white;
    padding: 12px;
    border-radius: 8px;
}
.form>label{
    font-weight: 500;
}

.login-reg-btn{
    margin-bottom: 1%;
    line-height: 2.5;
    font-size: 22px;
    background-color: #2750a3;
    border: 1px solid #1e1e8d;
    align-items: center;
    align-self: center;
    align-content: center;
}
/* .me-auto{
    padding-left: 6%;
} */
.me-auto>a , .nav-item>a, div>a{
    color: black !important;
}

.backgroungCls{
   /* background:  linear-gradient(to top left, #0000ff 0%, #66ff33 100%); */
   height: 97vh;
   
}
.bg-body-tertiary{
    width: 94%;
}


#countryDropdown{
    text-align: center;
    width: 25vw !important;
    height: 39px;
    margin-right: 9px !important;
}




/* //// */
.header{
    height:90px;
    background-color: transparent !important;
}
.colaps{
    background-color: rgb(9, 9, 88);
    margin-left: 333px;
    margin-right: 188px;
    padding-left: 18px;
    color: white;
}
.basic-nav-dropdown> a{
    color: white;
}
.dropdown> a{
color: white;
}

.dropdown-menu{
    background-color: white;
    
}
.dropdown-item:hover{
    background-color: blue;
}

.images{
    height: 50%;
    width: 100%;
  
    background-color: #FEFFEF;
}

.datas{
    padding-left: 18px;
    padding-top: 12px;
}
.datas>h4{
    /* background-color: blue; */
     color: black;
     font-size: 60px;
     font-weight: 900;
}
.datas>p{
    font-size: 24px;
    font-weight: 500;

}
.explore{
    background: blue;
    position: absolute;
    top: 68px;
    left: 18px;
    color: white;
    padding-left: 10px;
    padding-right: 10px;
}

.colaps{
    background-color: white !important;
    margin-left: 38%;
    margin-right: 3%;
    padding-left: 18px;
    color: black !important;
}

@media (max-width: 991px), (max-width: 768px) {
    .colaps{
        background-color: white !important;
    margin-left: 1%;
    margin-right: 71%;
    padding-left: 0px;
    color: black !important;
    }
  }


  .toggle-btn {
    background-color: white;
    color: black;
  }
  
  .toggle-btn.active {
    background-color:green;
    color: white;
  }
  